import React from 'react'
import PropTypes from 'prop-types'
import { Link } from './developed-by.css'

const DevelopedBy = ({ href, content }) => (
    <Link href={href} target="_blank" rel="noopener noreferrer">
        {content}
    </Link>
)

DevelopedBy.propTypes = {
    href: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
}

export default DevelopedBy
