import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Colors from 'constants/colors'

export const Link = styled.a`
    text-decoration: none;
    color: ${Colors.blueDark};
    font-size: 18px;
    font-weight: 500;

    ${MEDIA.MIN_MEDIUM`
        &:hover {
            color: ${Colors.orangeDark};
        }
    `}
`
