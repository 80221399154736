import React from 'react'
import PropTypes from 'prop-types'
import { Text, AccentText } from './title.css'

const Title = ({ children, accentFirstWord, contrastText }) => {
    return accentFirstWord ? (
        <Text contrastText={contrastText}>
            <AccentText>{children.trim().split(' ')[0]}</AccentText>{' '}
            {children
                .trim()
                .split(' ')
                .slice(1)
                .join(' ')}
        </Text>
    ) : (
        <Text contrastText={contrastText}>{children}</Text>
    )
}

Title.propTypes = {
    children: PropTypes.string.isRequired,
    accentFirstWord: PropTypes.bool,
    contrastText: PropTypes.bool
}

export default Title
