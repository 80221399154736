import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Theme from 'constants/theme'

export const Text = styled.h2`
    line-height: 2;
    font-weight: 400;
    font-size: 3rem;
    text-rendering: optimizelegibility;
    -webkit-font-smoothing: antialiased;

    color: ${({ contrastText, theme }) => () => (theme === 'dark' || contrastText ? Theme.light.palette.text.alternative : Theme.light.palette.text.primary)};

    ${MEDIA.SMALL`
        font-size: 2.6rem;
    `};
`

export const AccentText = styled.span`
    color: ${Theme.light.palette.text.tertiary};
`
