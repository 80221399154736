import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Colors from 'constants/colors'

export const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    ${MEDIA.SMALL`
        flex-direction: column;
    `};
`

export const Button = styled.a`
    width: 5em;
    color: ${Colors.blue};
    margin: 4rem 1rem 2rem;
    text-decoration: none;
    line-height: 1;

    ${MEDIA.MIN_MEDIUM`
        &:hover {
            color: ${Colors.blueDark};
            animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
        }
    `};
`
