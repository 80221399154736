import React from 'react'
import { MainLogo, LogoImg } from './logo.css'

const Logo = () => (
    <MainLogo>
        <LogoImg />
    </MainLogo>
)

export default Logo
