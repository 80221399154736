import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import Theme from 'constants/theme'

export const Row = styled.div`
    display: flex;
    text-align: ${({ align }) => () => `${align}`};
    flex-wrap: wrap;

    ${MEDIA.SMALL`
        flex-direction: column;
    `};
`

export const Column = styled.div`
    flex: ${({ col }) => () => `${col}%`};
    padding: 2rem;

    ${MEDIA.MEDIUM`
        flex: ${({ col }) => () => `${col * 2}%`};
    `};
`

export const Title = styled.div`
    display: inline-flex;
    font-size: 2rem;
    text-transform: uppercase;
    line-height: 36px;
    font-weight: 300;
    margin-bottom: 1rem;
    letter-spacing: 1px;
    color: ${Theme.light.palette.text.secondary};
`

export const Body = styled.div`
    font-size: 1.8rem;
    line-height: 1.7;
    color: ${Theme.light.palette.text.primary};
`
