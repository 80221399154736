import styled from 'styled-components'

export const StyledVideo = styled.video`
    width: 100% !important;
    height: auto !important;
    max-width: 800px;
    margin-top: 50px;
    outline: none;
    border-radius: 10px;
`
