import React from 'react'
import PropTypes from 'prop-types'
import { StyledVideo } from './video.css'

const Video = ({ url }) => (
    <StyledVideo width="100%" controls controlsList="nodownload noremoteplayback" poster="https://binsensors.com/poster.webp">
        <source src={url} type="video/mp4"></source>
        <track default kind="captions" srcLang="es" />
        Tu navegador no soporta HTML5 video :(
    </StyledVideo>
)

Video.propTypes = {
    url: PropTypes.string.isRequired
}

export default Video
