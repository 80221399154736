import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import ScrollSignSvg from '../../images/icons/chevrondown.svg'

export const AnchorLinkStyled = styled(AnchorLink)`
    ${MEDIA.MEDIUM`
        display: none;
    `};
`

export const ScrollSignStyled = styled(ScrollSignSvg)`
    margin-top: 30px;
    width: 25px;
    stroke: #20232a;
    animation: verticalScroll ease 1.5s infinite;
`
