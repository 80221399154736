import React from 'react'
import PropTypes from 'prop-types'
import { Row, Column, Title, Body } from './columns.css'

const Columns = ({ items, col = 20, align = 'left' }) => {
    return (
        <Row align={align}>
            {items?.map((item, i) => (
                <Column key={i} col={col}>
                    <React.Fragment>
                        <Title>{item.title}</Title>
                        <Body>{item.content}</Body>
                    </React.Fragment>
                </Column>
            ))}
        </Row>
    )
}

Columns.propTypes = {
    items: PropTypes.array.isRequired,
    col: PropTypes.number,
    align: PropTypes.string
}

export default Columns
