import React from 'react'
import PropTypes from 'prop-types'
import { Line } from './divider.css'

const Divider = ({ width }) => <Line width={width} />

Divider.propTypes = {
    width: PropTypes.number
}

export default Divider
