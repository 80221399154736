import styled from 'styled-components'
import IsoLogoSvg from '../../images/isologo.svg'

export const MainLogo = styled.div`
    width: 50%;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
`

export const LogoImg = styled(IsoLogoSvg)`
    width: 80%;
    max-width: 500px;
`
