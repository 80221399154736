import React from 'react'
import { AnchorLinkStyled, ScrollSignStyled } from './scroll-sign.css'

const ScrollSign = () => {
    return (
        <AnchorLinkStyled to="/#video">
            <ScrollSignStyled />
        </AnchorLinkStyled>
    )
}

export default ScrollSign
