import React from 'react'
import PropTypes from 'prop-types'
import { Container, Button } from './contact-button.css'
import EmailSvg from '../../images/contact/email.svg'
import WhatsAppSvg from '../../images/contact/whatsapp.svg'
import PhoneSvg from '../../images/contact/phone.svg'

const ContactButton = ({ title, type, url }) => (
    <Container>
        {type === 'phone' && (
            <Button title={title} href={url} target="_blank" rel="noopener noreferrer" alt="Phone">
                <PhoneSvg alt="Phone" />
            </Button>
        )}

        {type === 'email' && (
            <Button title={title} href={url} target="_blank" rel="noopener noreferrer" alt="Email">
                <EmailSvg alt="Email" />
            </Button>
        )}

        {type === 'whatsapp' && (
            <Button title={title} href={url} target="_blank" rel="noopener noreferrer" alt="WhatsApp">
                <WhatsAppSvg alt="WhatsApp" />
            </Button>
        )}
    </Container>
)

ContactButton.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired
}

export default ContactButton
