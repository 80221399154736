import styled from 'styled-components'
import MEDIA from 'helpers/mediaTemplates'

export const Buttons = styled.div`
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;

    ${MEDIA.SMALL`
        flex-direction: column;
        justify-content: center;
        align-items: center;
    `};
`
