import React from 'react'
import PropTypes from 'prop-types'
import { Buttons } from './contact-buttons.css'
import ContactButton from 'components/contact-buttons/contact-button'

const ContactButtons = ({ buttons }) => (
    <Buttons>
        {buttons.map((button, i) => (
            <ContactButton key={i} title={button.title} type={button.type} url={button.url} />
        ))}
    </Buttons>
)

ContactButtons.propTypes = {
    buttons: PropTypes.array.isRequired
}

export default ContactButtons
